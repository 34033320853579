@import "./project-variables.scss";

html, body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
textarea, input, button {
    font-family: 'Roboto', sans-serif;
}

.el-dialog__body, .el-popover{
    word-break: normal;
}

button i.fab, button i.fas {
    padding: 0 5px 0 0;
}

.el-dialog__body{
    padding-top:10px;
    padding-bottom:10px;
}

.el-menu--horizontal>.el-menu-item{
    height:40px;
    line-height:40px;
}
.el-menu.el-menu--horizontal {
    border-bottom: inherit;
}

.el-menu--horizontal>.el-submenu, .el-menu--horizontal>.el-submenu .el-submenu__title{
    height:40px;
    line-height:40px;
}

.el-table .cell{
    word-break: normal;
}




.el-tree-node__content:hover {
    background-color: #FFF9EE;
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color: #ffe6c5;
}







.el-button{
    text-transform: uppercase;
    font-weight: bold;
    /*color: black;*/
}

.el-button.button-icon-green i{
    color: $--color-success;
}



.el-button--primary.is-disabled, .el-button--primary.is-disabled:hover, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:active {
    color: $button-disabled-text-color;
}
.el-button.el-button--text.is-disabled{
    color: $--color-primary;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}



.el-button.table-action-button {
    padding: 5px 5px;
}
.el-button.table-action-button i {
    padding: 0;
}





.el-form-item--small.el-form-item {
    margin-bottom: 10px;
}
.el-form-item.el-form-item--small label.el-form-item__label {
    padding:0;
}

a, a:visited{
    color: unset;
}

.card-stages{
    font-size: 0.9rem;
}
.card-section{
    font-size: 1.1rem;

    .el-table{
        font-size: 0.8rem;
    }
}


.card-section-elements-table-header th {
    background-color: #f5f5f5 !important;
}

// в создании карт у этапов убираем пустоту из-за отсутствия дат
.card-edit-dialog-stage .card-edit-dialog-stage-content {
    margin-top: -50px;
}





.card-section{
    margin-bottom: 15px !important;
}

.el-table .card-section-elements-table-header th{
    padding: 2px 0;

    div {
        line-height: 1.3;
    }
}

.orgchart-container .node-card-block .el-table tr div{
    line-height: 1.0;
}

.timeline .negotiation-bottom-icon{
    top:-13px !important;
}